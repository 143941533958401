<template>
  <b-card-code title="Lista de Solicitações">
    <b-row>
      <b-col
        md="6"
        xl="2"
        class="mb-1"
      >
        <b-form-group
          label="Nº Solicitação:"
        >
          <b-form-input
            id="numsoliproduto"
            placeholder=""
            v-model="FiltroTela.numsoliproduto"
          />
        </b-form-group>
      </b-col>
      <b-col
        md="6"
        xl="3"
        class="mb-1"
      >
        <b-form-group
          label="Status:"
          
        >
          <b-form-select
          v-model="FiltroTela.statusworkflowidSelected"
          :options="statusworkflow">
          </b-form-select>
        </b-form-group>
      </b-col>

    <b-col
        md="6"
        xl="4"
        class="mb-1"
    >
    <br>
    <div class="DivBotaoLadoLado" >
     <b-button  variant="primary"
      type="button" @click="ConsultaSolicitacaoDesenvolvimento"><span>BUSCAR</span></b-button>
    </div>
    <div class="DivBotaoLadoLado" >
     <b-button  variant="success"
      type="button" @click="$router.push('DevProdutoFormulario')"><span>NOVA</span></b-button>
    </div>

    </b-col>
    </b-row>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      table-class = "font-size11"
      thead-class = "font-size11"
      thead-tr-class = "font-size11"
    >
    <template #cell(solidesenvolvimentoid)="row">
        <b-col
        md="6"
        xl="1"
        class="mb-1"
        >
        <b-button
          size="sm"
          style="width: 163px;"
          @click="RedirecionaDetalhe(row.item.solidesenvolvimentoid,'DevProdutoFormulario')" class="mr-1">
          FORMULARIO
        </b-button>
        </b-col>

        <b-col
        md="6"
        xl="1"
        class="mb-1"
        >
        <b-button size="sm"
         style="width: 163px;"
          variant="warning"
          v-if="row.item.acessoformanalise == 1"
          @click="RedirecionaDetalhe(row.item.solidesenvolvimentoid,'DevProdutoFormularioAnalise')" class="mr-1">
          ANALISE
        </b-button>
        </b-col>

       <b-col
        md="6"
        xl="1"
        class="mb-1"
        >
        <b-button size="sm"
          style="width: 163px;"
          v-if="row.item.acessoformdesenvolvimento == 1"
          @click="RedirecionaDetalhe(row.item.solidesenvolvimentoid,'DevProdutoFormularioDev')" class="mr-1">
          DESENVOLVIMENTO
        </b-button>
        </b-col>

        <b-col
        md="6"
        xl="1"
        class="mb-1"
        >
        <b-button size="sm"
          variant="danger"
          style="width: 163px;"
          v-if="row.item.acessoformamo == 1"
          @click="RedirecionaDetalhe(row.item.solidesenvolvimentoid,'DevProdutoFormularioAMO')" class="mr-1">
          A.M.O.
        </b-button>
        </b-col>
      </template>
    <template #cell(comentglobal)="row">
     <b-col xl="12">
    <b-form-textarea
            id="comentglobal"
            placeholder=""
            v-model="row.value"
            rows="3"
            :readonly="'readonly'"
          />
       </b-col>
    </template>
      <template #cell(data)="row">
         {{ TextFormat(row.value,'date') }}
      </template>
    </b-table>
    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Registros por Pagina"
        label-cols="8"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
</b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import FiltrosPortal from '@/customPortal/FiltrosPortal.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'
import Spinner from '@/components/Spinner.vue'

import {
  BRow, BFormGroup,
  BTable, BFormSelect, BPagination,
  BCardBody, BFormInput, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BCardCode,
    BFormGroup,
    BRow,
    BTable,
    BFormSelect,
    BPagination,
    BCardBody,
    BCol,
    BButton,
    BFormTextarea,
  },
  data() {
    return {
      FiltroTela: {
        TitloTela: 'SolicitacaoDesenvolvimento',
        Selecionado: false,
        numsoliproduto: '',
        statusworkflowidSelected: 0,
        FiltroComplementar: [],
      },
      statusworkflow: [
        { value: 0, text: 'Todos' },
      ],
      perPage: 10,
      pageOptions: [3, 5, 10, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'solidesenvolvimentoid', label: 'AÇÕES' },
        { key: 'numsoliproduto', label: 'Numero', sortable: true },
        { key: 'produto', label: 'Produto', sortable: true },
        { key: 'nomeusu', label: 'Solicitante', sortable: true },
        { key: 'data', label: 'Data', sortable: true },
        { key: 'status', label: 'Status', sortable: false },
      ],
      /* eslint-disable global-require */
      items: [
      ],
    }
  },
  mounted() {
    // Seta q quantidade de Linhas no Grid
    this.totalRows = this.items.length
    // Consulta Dados para Filtro na Tela
    this.ConsultaStatusWorkFlow()
    this.DescarregarFiltroTela()
  },
  methods: {
    async ConsultaStatusWorkFlow() {
      let notification = ''
      const usuario = this.$session.get('usuario')
      const STATUSWORKFLOWLOCID = '1' // PSKYSOLDEV
      const URLpost = `/StatusWorkFlow/StatusWorkFlow?CODUSU=${usuario.codusu}&STATUSWORKFLOWLOCID=${STATUSWORKFLOWLOCID}`
      await apiPortalSky.post(URLpost, STATUSWORKFLOWLOCID).then(response => {
        if (response.status === 200) {
          response.data.forEach((value, index) => {
            const Opcao = { sequencia: index, value: value.statusworkflowid, text: value.descricao }
            this.statusworkflow.push(Opcao)
          })
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })
    },
    async ConsultaSolicitacaoDesenvolvimento() {
      Spinner.StarLoad()
      let notification = ''

      const usuario = this.$session.get('usuario')
      const URLpost = `/SoliDesenvolvimento/SoliDesenvolvimentoConsulta?CODUSU=${usuario.codusu}&NUMSOLIPRODUTO=${this.FiltroTela.numsoliproduto}&STATUSWORKFLOWID=${this.FiltroTela.statusworkflowidSelected}`
      this.CarregaFiltroTela()
      await apiPortalSky.post(URLpost, 'this.numsoliproduto').then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.items = response.data
            this.totalRows = this.items.length
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }
    },
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    RedirecionaDetalhe(id, rota) {
      const $rota = `${rota}/${id}`
      this.$router.push($rota)
    },
    CarregaFiltroTela() {
      const usuario = this.$session.get('usuario')
      usuario.filtrosTela = FiltrosPortal.AdcionarFiltro(usuario, this.FiltroTela)
      this.$session.set('usuario', usuario)
    },
    DescarregarFiltroTela() {
      const usuario = this.$session.get('usuario')
      const Filtrado = FiltrosPortal.RetornaFiltro(usuario, this.FiltroTela.TitloTela)
      if (Filtrado.Selecionado) {
        // Realiza Validacao de Campo da Tela para Executar Realmente o Filtro
        if (Filtrado.numsoliproduto !== '') {
          this.FiltroTela = Filtrado// Carrega o Filtro

          // Descarrega Filtro Complementar
          //this.FiltroTela.FiltroComplementar.forEach(ftComplementar => {
          //  switch (ftComplementar.parametro) {
          //    case "":
          //      break
          //     
          //  }

          this.ConsultaSolicitacaoDesenvolvimento() // Executa o Filtro
        }
      }
    },
  },
  computed: {
  },
}
</script>
